import { EntityType } from '../../../types.generated';

export const CSV = 'csv';
export const EXCEL = 'excel';

// TODO(Gabe): integrate this w/ the theme
export const REDESIGN_COLORS = {
    GREY: '#e5e5e5',
    BLUE: '#1890FF',
};

export const ANTD_GRAY = {
    1: '#FFFFFF',
    2: '#FAFAFA',
    2.5: '#F8F8F8',
    3: '#F5F5F5',
    4: '#F0F0F0',
    4.5: '#E9E9E9',
    5: '#D9D9D9',
    6: '#BFBFBF',
    7: '#8C8C8C',
    8: '#595959',
    9: '#434343',
};

export const ANTD_GRAY_V2 = {
    1: '#F8F9Fa',
    2: '#F3F5F6',
    5: '#DDE0E4',
    6: '#B2B8BD',
    8: '#5E666E',
    10: '#1B1E22',
};

export const EMPTY_MESSAGES = {
    documentation: {
        title: 'No documentation yet',
        description: 'Share your knowledge by adding documentation and links to helpful resources.',
    },
    tags: {
        title: 'No tags added yet',
        description: 'Tag entities to help make them more discoverable and call out their most important attributes.',
    },
    terms: {
        title: 'No terms added yet',
        description: 'Apply glossary terms to entities to classify their data.',
    },
    owners: {
        title: 'No owners added yet',
        description: 'Adding owners helps you keep track of who is responsible for this data.',
    },
    properties: {
        title: 'No properties',
        description: 'Properties will appear here if they exist in your data source.',
    },
    queries: {
        title: 'No queries yet',
        description: 'Create, view, and share commonly used queries for this dataset.',
    },
    domain: {
        title: 'No domain set',
        description: 'Group related entities based on your organizational structure using by adding them to a Domain.',
    },
    dataProduct: {
        title: 'No data product set',
        description: 'Group related entities based on shared characteristics by adding them to a Data Product.',
    },
    contains: {
        title: 'Contains no Terms',
        description: 'Terms can contain other terms to represent a "Has A" style relationship.',
    },
    inherits: {
        title: 'Does not inherit from any terms',
        description: 'Terms can inherit from other terms to represent an "Is A" style relationship.',
    },
    'contained by': {
        title: 'Is not contained by any terms',
        description: 'Terms can be contained by other terms to represent a "Has A" style relationship.',
    },
    'inherited by': {
        title: 'Is not inherited by any terms',
        description: 'Terms can be inherited by other terms to represent an "Is A" style relationship.',
    },
    'type of': {
        title: 'Contains No Terms / Term Groups',
        description: 'Terms can be type of concept represented in a Term Group.',
    },
    'has type': {
        title: 'Is not Has Type in any Terms / Term Groups',
        description: 'Terms can be Has Type concept represented in a Term Group.',
    },
    'node type of': {
        title: 'Contains No Term Groups',
        description: 'Term Groups can be type of concept represented in a Term Groups.',
    },
    'node has type': {
        title: 'Is not Has Type in any Term Groups',
        description: 'Term Groups can be Has Type concept represented in a Term Groups.',
    },
};

export const ELASTIC_MAX_COUNT = 10000;

// Here property gets map to get link label
export const PROPERTY_MAPPING = {
    data_system: 'Go to Data System',
    Definition: 'Go To Content Profile',
    data_asset_map: 'Go to Data Asset Map',
};

export const getElasticCappedTotalValueText = (count: number) => {
    if (count === ELASTIC_MAX_COUNT) {
        return `${ELASTIC_MAX_COUNT}+`;
    }

    return `${count}`;
};

export const ENTITY_TYPES_WITH_MANUAL_LINEAGE = new Set([
    EntityType.Dashboard,
    EntityType.Chart,
    EntityType.Dataset,
    EntityType.DataJob,
]);

export const GLOSSARY_ENTITY_TYPES = [EntityType.GlossaryTerm, EntityType.GlossaryNode];

export const DEFAULT_SYSTEM_ACTOR_URNS = ['urn:li:corpuser:__datahub_system', 'urn:li:corpuser:unknown'];

export const VIEW_ENTITY_PAGE = 'VIEW_ENTITY_PAGE';

// only values for Domain Entity for custom configurable default tab
export enum EntityProfileTab {
    DOMAIN_ENTITIES_TAB = 'DOMAIN_ENTITIES_TAB',
    DOCUMENTATION_TAB = 'DOCUMENTATION_TAB',
    DATA_PRODUCTS_TAB = 'DATA_PRODUCTS_TAB',
    PROPERTIES_TAB = 'PROPERTIES_TAB',
}

export const DATA_SHEET_TABS = [
    'Main Tab',
    'Properties',
    'Ownership',
    'Glossary Terms',
    'Tags',
    'Schema Field',
    'Schema Field Tags',
    'Schema Field Glossary Terms',
];

export const GLOSSARY_EXCEL_TABS = [
    'Glossary Term',
    'Glossary Term Properties',
    'Glossary Term Ownership',
    'Glossary Term Related Entities',
    'Glossary Term Related Terms',
];

// Sample Excel Sheet Data For Datasets
export const DATASET_SHEET_SAMPLE = [
    {
        sheetName: 'Main Tab',
        sheetData: [
            [
                'Dataset URN',
                'Subtype',
                'Name',
                'Description',
                'Domain URN',
                'Domain Name',
                'Governance Status',
                'PII Flag',
                'CDE Flag',
                'Data Product URN',
                'Data Product Name',
            ],
            // Underhere will be the data
        ],
    },
    {
        sheetName: 'Properties',
        sheetData: [['Dataset URN', 'Dataset Name', 'Property Name', 'Property Value']],
    },
    {
        sheetName: 'Ownership',
        sheetData: [['Dataset URN', 'Dataset Name', 'Owner', 'Ownership Type URN', 'Ownership Type Name']],
    },
    {
        sheetName: 'Glossary Terms',
        sheetData: [['Dataset URN', 'Dataset Name', 'Term URN', 'Term Name']],
    },
    {
        sheetName: 'Tags',
        sheetData: [['Dataset URN', 'Dataset Name', 'Tag URN', 'Tag Name']],
    },
    {
        sheetName: 'Schema Field',
        sheetData: [['Dataset Field URN', 'Dataset Name', 'Field Name', 'Description', 'PII Flag', 'CDE Flag']],
    },
    {
        sheetName: 'Schema Field Tags',
        sheetData: [['Dataset Field URN', 'Dataset Name', 'Field Name', 'Tag URN', 'Tag Name']],
    },
    {
        sheetName: 'Schema Field Glossary Terms',
        sheetData: [['Dataset Field URN', 'Dataset Name', 'Field Name', 'Term URN', 'Term Name']],
    },
];

// Sample Excel Sheet Data For Glossary
export const EXPECTED_HEADERS = {
    dataset_schema: {
        name: 'dataset_schema.csv',
        header: ['Schema URN', 'Description', 'Tags', 'Glossary Terms'],
        data: [
            '<schema_urn>',
            '<description>',
            '"<urn_tags> and make sure that multiple tags use the pipe operator, like this urn_tag_1|urn_tag_2"',
            '"<urn_terms> and make sure that multiple terms use the pipe operator, like this urn_term_1|urn_term_2"',
        ],
    },
    dataset: {
        name: 'dataset.csv',
        header: ['Dataset URN', 'Description', 'Tags', 'Glossary Terms', 'Domain', 'Owners'],
        data: [
            '<dataset_urn>',
            '<description>',
            '"<urn_tags> and make sure that multiple tags use the pipe operator, like this urn_tag_1|urn_tag_2"',
            '"<urn_terms> and make sure that multiple terms use the pipe operator, like this urn_term_1|urn_term_2"',
            '<domain_urn>',
            '"<owner_email:owner_type> and make sure that multiple owners use the pipe operator and owner_type should be one of them <TECHNICAL_OWNER | BUSINESS_OWNER | DATA_STEWARD | NONE>, like this email:TECHNICAL_OWNER|email:BUSINESS_OWNER"',
        ],
    },
};

export const DOWNLOAD_SAMPLE_BUTTONS_CONFIG = {
    [CSV]: {
        name: 'Download Sample CSV',
        tooltip:
            'Upload the CSV file following the provided sample template. Ensure that column placement aligns with the template for accurate data processing.',
        type: CSV,
    },
    [EXCEL]: {
        name: 'Download Sample Excel',
        tooltip:
            'Upload the Excel file following the provided sample template. Ensure that column placement aligns with the template for accurate data processing.',
        type: EXCEL,
    },
};

export const GLOSSARY_CSV_TEMPLATE = {
    name: 'template.csv',
    header: [
        'name',
        'description',
        'ownersUsers',
        'ownersGroups',
        'termSource',
        'sourceRef',
        'sourceUrl',
        'inherits',
        'contains',
        'customProperties',
        'knowledgeLinks',
        'domain',
    ],
    data: [
        'Customer Segmentation Test',
        'Describes the process of dividing customers into groups based on shared characteristics',
        '<email>',
        '<email>',
        'External',
        'CSG-001',
        'https://example.com/glossary/customer-segmentation',
        '',
        '',
        'name: Segmentation Type',
        '"[{""url"": ""www.test.com"", ""label"": ""this is wiki""}]"',
        '<urn>',
    ],
};

// Sample Excel Sheet Data For Datasets
export const GLOSSARY_EXCEL_TEMPLATE = [
    {
        sheetName: 'Glossary Term',
        sheetData: [
            [
                'Glossary URN',
                'Parent Path',
                'Parent Node URN',
                'Glossary Term Name',
                'Description',
                'Domain URN',
                'Domain Name',
                'Governance Status',
                'PII Flag',
                'CDE Flag',
            ],
        ],
    },
    {
        sheetName: 'Glossary Term Properties',
        sheetData: [['Glossary URN', 'Parent Path', 'Glossary Name', 'Property Name', 'Property Value']],
    },
    {
        sheetName: 'Glossary Term Ownership',
        sheetData: [
            ['Glossary URN', 'Parent Path', 'Glossary Name', 'Owner', 'Ownership Type URN', 'Ownership Type Name'],
        ],
    },
    {
        sheetName: 'Glossary Term Related Entities',
        sheetData: [
            [
                'Glossary URN',
                'Parent Path',
                'Glossary Name',
                'Entity URN',
                'Entity Type',
                'Entity Subtype',
                'Entity Name',
            ],
        ],
    },
    {
        sheetName: 'Glossary Term Related Terms',
        sheetData: [['Glossary URN', 'Parent Path', 'Glossary Name', 'Relationship', 'Term URN', 'Term Name']],
    },
];
